import React from "react";
import Adminlogin from "../component/admin-login/Adminlogin";

function Homepage() {
  return (
    <>
      <Adminlogin />
    </>
  );
}

export default Homepage;
